var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(_vm._s(_vm.$t('MAIN_MENU.USERS')))])]),_c('div',{staticClass:"card-toolbar"},[(
            _vm.currentUserRole === 'ROLE_ROOT' ||
            _vm.currentUserRole === 'ROLE_SUPER_ADMIN'
          )?_c('router-link',{staticClass:"btn btn-primary font-weight-bolder",attrs:{"to":"/users/new"}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/User.svg"}})],1),_vm._v("Add New User ")]):_vm._e()],1)]),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"table-responsive mb-0 pb-0"},[_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg"},[_c('thead',[_c('tr',{staticClass:"text-left text-uppercase"},[_c('th',{staticClass:"pl-6",staticStyle:{"min-width":"120px"}},[_vm._v(" Email, Name & Surname ")]),_c('th',{staticStyle:{"min-width":"120px"}},[_vm._v("Role")]),(
                  _vm.currentUserRole === 'ROLE_ROOT' ||
                  _vm.currentUserRole === 'ROLE_SUPER_ADMIN' ||
                  _vm.currentUserRole === 'ROLE_ADMIN'
                )?_c('th',{staticClass:"pr-6",staticStyle:{"min-width":"160px","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.ACTIONS'))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.users),function(user,key){return _c('tr',{key:key},[_c('td',{staticClass:"pl-6",class:{ 'border-top-0': key === 0 }},[(
                    _vm.currentUserRole === 'ROLE_ROOT' ||
                    _vm.currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    _vm.currentUserRole === 'ROLE_ADMIN'
                  )?_c('router-link',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg",attrs:{"to":'/users/edit/' + user.id}},[_vm._v(_vm._s(user.email))]):_c('span',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"},[_vm._v(_vm._s(user.email))]),_c('div',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Ime: ")]),_c('span',{staticClass:"text-muted font-weight-bold text-hover-primary"},[_vm._v(_vm._s(user.name)+" ")]),_c('span',{staticClass:"text-muted font-weight-bold text-hover-primary"},[_vm._v(_vm._s(user.surname))])])],1),_c('td',{class:{ 'border-top-0': key === 0 }},[_c('span',{staticClass:"label label-lg label-light-primary label-inline mr-2"},[_vm._v(_vm._s(user.roles[0]))])]),(
                  _vm.currentUserRole === 'ROLE_ROOT' ||
                  _vm.currentUserRole === 'ROLE_SUPER_ADMIN' ||
                  _vm.currentUserRole === 'ROLE_ADMIN'
                )?_c('td',{staticClass:"text-right pr-6",class:{ 'border-top-0': key === 0 }},[(
                    _vm.currentUserRole === 'ROLE_ROOT' ||
                    _vm.currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    _vm.currentUserRole === 'ROLE_ADMIN'
                  )?_c('router-link',{staticClass:"btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2",attrs:{"to":'/users/edit/' + user.id}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Settings-1.svg"}})],1)]):_vm._e(),(
                    _vm.currentUserRole === 'ROLE_ROOT' ||
                    _vm.currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    _vm.currentUserRole === 'ROLE_ADMIN'
                  )?_c('a',{staticClass:"btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon",attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteEntity(user.id)}}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Trash.svg"}})],1)]):_vm._e()],1):_vm._e()])}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }